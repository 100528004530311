'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaDispatching.factory:DisplayLabel

 # @description

###
angular
  .module 'lpaDispatching'
  .factory 'DisplayLabel', [
    '$log'
    '$cookies'
    ($log, $cookies) ->
      DisplayLabelBase = {}

      DisplayLabelBase.getDefault = () ->
        return "brabo"

      DisplayLabelBase.getOptions = () ->
        options = [
          key: "brabo"
          label: "app.dispatch.brabo"
        ,
          key: "al"
          label: "app.dispatch.al"
        ]

        options

      DisplayLabelBase.getActiveKey = () ->
        activeKey = $cookies.get('activeDisplayLabel')
        if activeKey
          return activeKey
        else
          return @getDefault()

      DisplayLabelBase.save = (key) ->
        $cookies.put 'activeDisplayLabel', key

      DisplayLabelBase

    ]
